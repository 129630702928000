import React from "react"
import { SRLWrapper } from "simple-react-lightbox"

const Filters = ({ onClickAll, all, onClick, filters }) => (
  <form>
    <ul className="gallery-filters">
      <li onClick={onClickAll}>
        <input type="checkbox" checked={all} onChange={e => {}} />
        <label htmlFor="all" className="filter-all">
          Tümü
        </label>
      </li>
      {filters.map((filter, i) => (
        <li key={i} data-index={i} onClick={onClick}>
          <input
            id={filter.name}
            type="checkbox"
            checked={filter.status}
            onChange={e => {}}
          />
          <label htmlFor={filter.name}>{filter.name}</label>
        </li>
      ))}
    </ul>
  </form>
)

const Cards = ({ imgs }) => (
  <SRLWrapper>
    <ul className="gallery-imgs">
      {imgs.map((img, i) => (
        <li key={i}>
          <figure>
            <img src={img.src} alt={img.title} srl_gallery_image="true" />
          </figure>
        </li>
      ))}
    </ul>
  </SRLWrapper>
)

export class Gallery extends React.Component {
  state = {
    imgs: this.props.images,
    filters: this.props.filters,
    all: true,
  }
  static getDerivedStateFromProps(props, current_state) {
    if (current_state.filters !== props.filters) {
      return {
        current_state,
        filters: props.filters,
        imgs: props.images,
      }
    }
    return null
  }

  setFilter = e => {
    e.preventDefault()
    const { filters } = this.state
    const { index } = e.currentTarget.dataset

    filters[index].status = !filters[index].status
    this.setState({
      filters,
    })

    this.updateFilters()
    this.updateImgs()
  }

  setAll = () => {
    const { filters } = this.state

    filters.forEach(filter => {
      filter.status = false
    })

    this.setState({
      all: true,
      filters,
    })
  }

  updateFilters() {
    const allFiltersTrue = this.props.filters.every(
      filter => filter.status === true
    )
    const allFiltersFalse = this.props.filters.every(
      filter => filter.status === false
    )

    if (allFiltersTrue || allFiltersFalse) {
      this.setAll()
    } else {
      this.setState({
        all: false,
      })
    }
  }

  updateImgs() {
    const { filters } = this.state
    let newImgs = []
    let a = 0

    this.props.images.forEach((img, imgKey) => {
      filters.forEach((filter, filterKey) => {
        if (img.tag === filter.name && filter.status === true) {
          newImgs[a] = img
          a++
        }
      })
    })

    this.setState({
      imgs: newImgs,
    })
  }

  render() {
    const { filters, all } = this.state
    return (
      <div>
        <Filters
          onClickAll={this.setAll}
          all={all}
          onClick={this.setFilter}
          filters={filters}
        />
        {all ? (
          <Cards imgs={this.props.images} />
        ) : (
          <Cards imgs={this.state.imgs} />
        )}
      </div>
    )
  }
}

Gallery.defaultProps = {
  filters: [],
  images: [],
}

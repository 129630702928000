import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import $ from "jquery"
import { useTranslation, useI18next, Link } from "gatsby-plugin-react-i18next"
import sliderVideo from "../assets/img/video-desktop.mp4"
import sliderVideoM from "../assets/img/video-mobile.mp4"
import roomStandard from "../assets/img/standard-room.jpg"
import roomSuit from "../assets/img/suit-room.jpg"
import map from "../assets/img/map.png"
import { Gallery } from "../components/Gallery"
import SimpleReactLightbox from "simple-react-lightbox"
import ZeroWasteEn from "../assets/img/zerowaste.en.png"
import ZeroWasteTr from "../assets/img/zerowaste.tr.png"
import SafeTourismEn from "../assets/img/safe-tourism.en.png"
import SafeTourismTr from "../assets/img/safe-tourism.tr.png"
import SustainableTourismEn from "../assets/img/sustainable-tourism.en.png"
import SustainableTourismTr from "../assets/img/sustainable-tourism.tr.png"

export default function AnaSayfa({ data }) {
  const { t } = useTranslation()
  const { language } = useI18next()
  const [kategoriler, setKategoriler] = useState([])
  const [galeriler, setGaleriler] = useState([])

  useEffect(() => {
    if (data) {
      const cats = data?.kategoriler?.edges.map(edge => edge.node.frontmatter)
      let dogs = data?.galeri?.edges?.map(edge => edge.node.frontmatter)
      dogs = dogs?.map(dog => {
        const cat = cats?.find(cat => cat.title === dog.kategori)
        return {
          ...dog,
          galeri_sirasi: cat?.galeri_sirasi,
          src: dog.resim,
          tag: language === "tr" ? cat.title : cat.title_en,
          title: language === "tr" ? dog.baslik : dog.baslik_en,
        }
      })
      setKategoriler(
        cats.map(cat => ({
          name: language === "tr" ? cat.title : cat.title_en,
          status: false,
        })),
      )
      setGaleriler(
        dogs.sort((a, b) => {
          return a.galeri_sirasi > b.galeri_sirasi ? 1 : -1
        }),
      )
    }
  }, [data, language])

  useEffect(() => {
    var slideBtn = $(".slide-checkIn")
    var checkInList = $(".checkIn-list")
    $(slideBtn).click(function () {
      $(this).toggleClass("rotate")
      $(checkInList).stop(true).slideToggle()
    })
  }, [])

  var flip
  var flip1
  var roomType1
  var roomType2
  if (typeof document !== "undefined") {
    flip = document.getElementById("flipCard")
    flip1 = document.getElementById("flipCard1")
    roomType1 = document.getElementById("roomType1")
    roomType2 = document.getElementById("roomType2")
  }
  function flipCard() {
    flip.classList.toggle("clicked")
    roomType1.classList.toggle("flip")
  }
  function flipCard1() {
    flip1.classList.toggle("clicked")
    roomType2.classList.toggle("flip")
  }
  return (
    <Layout>
      <SEO title="Ana Sayfa" />
      <div className="sliderVideo-container">
        <video src={sliderVideo} id="sliderVideo" autoPlay muted />
        <video src={sliderVideoM} id="sliderVideoM" autoPlay muted />
      </div>
      <div className="full-containerBg">
        <div className="full-container">
          <div className="checkIn-info">
            <div className="checkIn-info-container">
              <div className="left">
                <span className="material-icons">call_made</span>
                <strong>Check-in:</strong>13:00
              </div>
              <div className="right">
                <strong>Check-out:</strong>12:00
                <span className="material-icons">call_received</span>
              </div>
              <div className="slide-checkIn">
                <span className="material-icons">arrow_drop_down</span>
              </div>
              <ul className="checkIn-list">
                <li>{t("checkin1")}</li>
                <li>{t("checkin2")}</li>
                <li>{t("checkin3")}</li>
                <li>{t("checkin6")}</li>
                <li>{t("checkin4")}</li>
                <li>{t("checkin5")}</li>
              </ul>
            </div>
          </div>
          <div className="aboutHotel">
            <div className="our-history">
              <div className="title">{t("ourHistory")}</div>
              <div className="description">{t("ourHistory-description")}</div>
            </div>
            <div className="about-us">
              <div className="title">{t("aboutUs")}</div>
              <div className="description">
                <p>{t("aboutUs-description1")}</p>
                <p>{t("aboutUs-description2")}</p>
                <p>{t("aboutUs-description3")}</p>
              </div>
            </div>
          </div>
          <div className="section rooms">
            <div className="title">{t("rooms")}</div>
            <div className="rooms-container">
              <div className="room-types suit" id="roomType1">
                <div className="roomTypes-title">{t("suite")}</div>
                <img src={roomSuit} alt="" />
                <div className="flip-details">{t("roomSuite")}</div>
                <div className="roomTypes-card">
                  <div className="card">{t("poolView")}</div>
                  <div className="card">{t("tv")}</div>
                  <div className="card">{t("seascape")}</div>
                  <div className="card">{t("balcony")}</div>
                  <div className="card">{t("gardenView")}</div>
                  <div className="card">{t("hotWater")}</div>
                  <div className="card">{t("miniRefrigerator")}</div>
                  <div className="card">{t("hairDryer")}</div>
                </div>
                <div className="roomTypes-buttons">
                  <Link to="/gallery/" className="goGallery">
                    {t("seeGallery")}
                  </Link>
                  <span
                    className="material-icons"
                    id="flipCard"
                    onClick={flipCard}
                  >
                    add_circle
                  </span>
                </div>
              </div>
              <div className="room-types standard" id="roomType2">
                <div className="roomTypes-title">{t("standard")}</div>
                <img src={roomStandard} alt="" />
                <div className="flip-details">{t("roomStandard")}</div>
                <div className="roomTypes-card">
                  <div className="card">{t("poolView")}</div>
                  <div className="card">{t("tv")}</div>
                  <div className="card">{t("balcony")}</div>
                  <div className="card">{t("hotWater")}</div>
                  <div className="card">{t("hairDryer")}</div>
                  <div className="card">{t("miniRefrigerator")}</div>
                </div>
                <div className="roomTypes-buttons">
                  <Link to="/gallery/" className="goGallery">
                    {t("seeGallery")}
                  </Link>
                  <span
                    className="material-icons"
                    id="flipCard1"
                    onClick={flipCard1}
                  >
                    add_circle
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="section gallery">
            <div className="title">{t("galleryTitle")}</div>
            <SimpleReactLightbox>
              <Gallery filters={kategoriler} images={galeriler} />
            </SimpleReactLightbox>
            <Link to="/gallery/" id="goGallery-btn">
              {t("allPhotos")}
            </Link>
          </div>
          <div className="section sustainability">
            <div className="title">{t("sustainabilityTitle")}</div>
            <div className="sustainability-buttons">
              <a href={"https://static.onurotel.net/files/Covid-19-Protokolu.pdf"}>{t("covid-protocol")}</a>
              <a href={"https://static.onurotel.net/files/Faaliyet-Protokolu.pdf"}>{t("activity-protocol")}</a>
              <a
                href={
                  "https://static.onurotel.net/files/surdurulebilirlik-raporu-2024.pdf"
                }
              >
                {t("sustainability-report")}
              </a>
            </div>
            <div className="sustainability-icons">
              <img
                src={language === "tr" ? SafeTourismTr : SafeTourismEn}
                alt=""
                width={120}
              />
              <img
                src={
                  language === "tr"
                    ? SustainableTourismTr
                    : SustainableTourismEn
                }
                alt=""
                width={120}
              />
              <img
                src={language === "tr" ? ZeroWasteTr : ZeroWasteEn}
                alt=""
                width={200}
              />
            </div>
          </div>
          <div className="section contact">
            <div className="left">
              <div className="title">{t("contactTitle")}</div>
              <div className="form-title">{t("contactForm")}</div>
              <form
                name="contact"
                className="contact-form"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bott-field"
              >
                <span style={{ display: "none" }}>
                  <input name="bott-field" />
                </span>
                <input type="hidden" name="form-name" value="contact" />
                <input
                  type="text"
                  name="name"
                  placeholder={t("name")}
                  required
                />
                <input
                  type="text"
                  name="surname"
                  placeholder={t("surname")}
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder={t("phone")}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder={t("mail")}
                  required
                />
                <textarea
                  name="message"
                  placeholder={t("message")}
                  rows="5"
                  required
                ></textarea>
                <button type="submit">
                  <span className="material-icons">send</span>
                </button>
                {language === "tr" && (
                  <div className="kvkk-area">
                    <label htmlFor="kvkk">
                      Kişisel Verilerin Korunması Kanunu kapsamında{" "}
                      <Link to="/aydinlatma-metni/" target="_blank">
                        Aydınlatma Metni
                      </Link>
                      'ni okudum, kabul ediyorum.
                      <input type="checkbox" name="kvkk" id="kvkk" required />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                )}
              </form>
            </div>
            <div className="right">
              <a href="#">
                <img src={map} alt="" />
              </a>
              <div className="contact-title">{t("contactInfo")}</div>
              <div className="contact-options">
                <a href="tel:+902523854092" className="option phone">
                  <span className="material-icons">phone</span>
                  +90 252 385 4092
                </a>
                <a href="mailto:info@onurotel.net" className="option mail">
                  <span className="material-icons">mail_outline</span>
                  info@onurotel.net
                </a>
                <a
                  href="https://g.page/OnurHotel?share"
                  target="_blank"
                  className="option address"
                  rel="noreferrer"
                >
                  <span className="material-icons">location_on</span>
                  Yalıkavak, Cumhuriyet Cd. No:43, 48990 Bodrum/Muğla
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Slides($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    kategoriler: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "galeri_kategori" } } }
      sort: { order: ASC, fields: frontmatter___galeri_sirasi }
    ) {
      edges {
        node {
          frontmatter {
            galeri_sirasi
            title
            title_en
          }
        }
      }
    }
    galeri: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "galeri" }, anasayfa: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            kategori
            resim
            baslik
            baslik_en
          }
        }
      }
    }
  }
`
